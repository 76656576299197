
import { Typography, TypographyProps } from "@mui/material";

interface CustomTypographyProps extends TypographyProps {
    children: string;
}

export const CustomTypography: React.FC<CustomTypographyProps> = (props) => {
    return (
        <Typography fontFamily={'Montserrat'} {...props}>
            {props.children}
        </Typography>
    )
}
