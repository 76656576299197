import HomeSection from "src/components/home-section"
import { FlexContainer } from "../components/ui/flex-container"
import { CustomersSection } from "src/components/customers-section"
import { WhySection } from "src/components/why-section"
import { LocaleSection } from "src/components/locale-section";
import { Footer } from "src/components/footer";
import { CommonQuestions } from "src/components/common-questions";
import { ClinicSections } from "src/components/clinic-section";
import { FakeFooter } from "src/components/fake-footer";
import { VideoSection } from "src/components/video-section";

export const MainPage = () => {


    return (
        <FlexContainer flex={1} flexDirection={'column'} width={'100%'}>
            <HomeSection />
            <VideoSection />
            <CustomersSection />
            <WhySection />
            <ClinicSections />
            <LocaleSection />
            <CommonQuestions />
            <FakeFooter />
            <Footer />
        </FlexContainer>
    );
};

