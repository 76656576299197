import { Box } from "@mui/material"
import { FlexContainer } from "./ui/flex-container"
import { CustomTypography } from "./ui/custom-typography";
import { Procedures } from "./procedures";

export const CustomersSection = () => {


    return (
        <FlexContainer
            id="procedures"
            sx={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                height: { xs: '135vh', sm: '70vh', md: '80vh', lg: '78vh', xl: '75vh'},
                width: '100%',
                background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(27,44,72,1) 100%)'
            }}>
            <CustomTypography
                sx={{
                    fontSize: { xs: '25px', md: '30px', lg: '42px' },
                    color: 'white',
                    fontWeight: '600',
                    textAlign: 'center'
                }}>
                Tratamentos realizados no consultório: 
            </CustomTypography>
            <Box 
                sx={{
                    width: { xs: '80%', md: '75%', lg: '85%' },
                }}>
                <Procedures />
            </Box>
        </FlexContainer>
    )
}