import { FlexContainer } from './components/ui/flex-container';
import { MainPage } from './pages/main-page';
import NavBar from './components/nav-bar';
import { WhatsappIcon } from './components/whatsapp/whatsapp-icon';
import './App.css'

function App() {
  return (
    <FlexContainer sx={{ flexDirection: 'column' }}>
      <NavBar />
      <WhatsappIcon />
      <MainPage />
    </FlexContainer>
  );
}

export default App;
