import { Button, ButtonProps } from "@mui/material"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { CustomTypography } from "../ui/custom-typography";
import { motion } from 'framer-motion'

export const WhatsappButton = (props: ButtonProps) => {
    const handleClick = () => {
        const url = 'https://wa.me/5521993302613';
        window.open(url, '_blank');
    };
    return (
        <motion.div
            initial={{ scale: 1 }}
            animate={{ scale: [1, 1.05, 1] }}
            transition={{ duration: 3, repeat: Infinity }}>
            <Button
                variant="contained"
                onClick={() => handleClick()}
                sx={{
                    backgroundColor: '#20243B',
                    textTransform: 'none',
                    width: 'auto',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ":hover": {
                        transform: 'scale(1.05)',
                        transition: 'transform 0.3s ease-in-out, color 0.3s ease-in-out',
                        backgroundColor: '#20243B',
                        textTransform: 'none',
                    },
                    transition: 'transform 0.3s ease-in-out, color 0.3s ease-in-out',
                    padding: { xs: '10px', md: 'auto' },
                    ...props.sx,
                }}
            >
                <WhatsAppIcon fontSize={"small"} sx={{ marginRight: 1 }} />
                <CustomTypography fontWeight={'600'}>Agendamento Whatsapp</CustomTypography>
            </Button>
        </motion.div>
    )
}