import { useState } from 'react';
import Accordion, { AccordionSlots } from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import { FlexContainer } from './ui/flex-container';
import { CustomTypography } from './ui/custom-typography';

export const CommonQuestions = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <FlexContainer
            sx={{
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                paddingY: { xs: '5%', lg: '6%' },
                height: 'auto'
            }}>
            <FlexContainer>
                <CustomTypography
                    sx={{
                        fontSize: { xs: 20, md: 25, lg: 35 },
                        fontWeight: 600,
                        marginBottom: '5%',
                    }}
                >
                    Perguntas Frequentes
                </CustomTypography>
            </FlexContainer>
            <FlexContainer
                sx={{
                    flexDirection: 'column',
                    width: { xs: '90%', sm: '85%', md: '75%', lg: '50%', xl: '42%' }
                }}>
                <Accordion
                    sx={{
                        background: 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(5,6,41,1) 21%, rgba(27,44,72,1) 100%)',
                        color: 'white',
                        border: '1px solid white'
                    }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <CustomTypography sx={{ fontWeight: 600 }}>O que são facetas em resina?</CustomTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomTypography sx={{ textAlign: 'justify' }}>
                            As facetas em resina são finas camadas de material
                            composto por resina que são aplicadas na parte frontal dos dentes,
                            com o objetivo de aprimorar sua estética. Elas têm diversas aplicações,
                            incluindo a correção de imperfeições como manchas, descolorações,
                            irregularidades e também para ajustar o tamanho e a forma dos dentes.
                        </CustomTypography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{
                        background: 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(5,6,41,1) 21%, rgba(27,44,72,1) 100%)',
                        color: 'white',
                        border: '1px solid white'
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <CustomTypography sx={{ fontWeight: 600 }}>
                            Quais são as vantagens das facetas em resina em comparação com outros materiais, como porcelana?
                        </CustomTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomTypography sx={{ textAlign: 'justify' }}>
                            As facetas em resina representam uma opção mais econômica em comparação
                            com as facetas de porcelana. Além disso, não é necessário desgaste nos
                            dentes naturais durante o processo de aplicação, o que torna uma
                            escolha menos invasiva. Outra vantagem das facetas em resina é a
                            capacidade de serem mais facilmente reparadas em caso de danos,
                            ou que proporciona uma opção prática para manutenção e cuidados a
                            longo prazo.
                        </CustomTypography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{
                        background: 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(5,6,41,1) 21%, rgba(27,44,72,1) 100%)',
                        color: 'white',
                        border: '1px solid white'
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        <CustomTypography sx={{ fontWeight: 600 }}>
                            Quanto tempo dura o processo de colocação de facetas em resina?
                        </CustomTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomTypography sx={{ textAlign: 'justify' }}>
                            O processo de colocação de facetas em resina normalmente
                            envolve de 1 a 2 consultas ao dentista, e cada consulta tem uma
                            duração de algumas horas. A duração do procedimento depende do número de
                            facetas que precisam ser aplicadas. Este processo é projetado para ser
                            eficiente e, com pouco tempo, pode proporcionar um sorriso renovado e
                            aprimorado.
                        </CustomTypography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{
                        background: 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(5,6,41,1) 21%, rgba(27,44,72,1) 100%)',
                        color: 'white',
                        border: '1px solid white'
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                    >
                        <CustomTypography sx={{ fontWeight: 600 }}>
                            As facetas em resina podem ser personalizadas para combinar com o núcleo e a forma dos meus dentes naturais?
                        </CustomTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomTypography sx={{ textAlign: 'justify' }}>
                            Facetas em resina são altamente personalizáveis.
                            O dentista colabora estreitamente com o paciente para selecionar o cor,
                            forma e outros detalhes, garantindo que as facetas se integrem o mais
                            harmoniosamente possível com os dentes naturais. Isso garante que o
                            resultado final seja esteticamente agradável e natural.
                        </CustomTypography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{
                        background: 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(5,6,41,1) 21%, rgba(27,44,72,1) 100%)',
                        color: 'white',
                        border: '1px solid white'
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                    >
                        <CustomTypography sx={{ fontWeight: 600 }}>
                            Quais cuidados e manutenção são necessários para garantir a longevidade das facetas da resina?
                        </CustomTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomTypography sx={{ textAlign: 'justify' }}>
                            Manter uma boa higiene bucal, que inclui escovação regular, o uso do fio dental e visitas periódicas ao dentista, é crucial para preservar a integridade das facetas em resina.
                            Além disso, é importante evitar hábitos prejudiciais, como morder objetos duros, roer unhas ou abrir embalagens com os dentes, pois essas ações podem danificar as facetas de resina.
                            Com os devidos cuidados, as facetas em resina podem ter uma longa vida útil.
                        </CustomTypography>
                    </AccordionDetails>
                </Accordion>
            </FlexContainer>
        </FlexContainer>
    );
}

