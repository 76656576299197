import { Divider } from "@mui/material"
import { CustomTypography } from "./ui/custom-typography"
import ScrollIntoView from 'react-scroll-into-view'
import { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { FlexContainer } from "./ui/flex-container"
import Logo from '../assets/logo.png'
import LogoMobile from '../assets/logoMobile.png'

/* enum SECTIONS {
    HOME = 'home',
    PROCEDURES = 'procedures',
    WHY = 'why',
    LOCALE = 'locale'
}

interface ScrollOffsets {
    home: number;
    procedures: number;
    why: number;
    locale: number;
}

const NavTypography: React.FC<{ children: string }> = ({ children }) => {
    return (
        <FlexContainer flexDirection={'column'}>
            <CustomTypography sx={{ color: '#20243B', fontWeight: 600, cursor: 'pointer' }}>
                {children}
            </CustomTypography>
        </FlexContainer>
    );
}; */

const NavBar: React.FC = () => {
    /* const [active, setActive] = useState<SECTIONS>(SECTIONS.HOME);
    const [scrollOffsets, setScrollOffsets] = useState<ScrollOffsets>({ home: 0, procedures: 0, why: 0, locale: 0 });

    const calculateOffsets = useCallback(() => {
        const getElementOffset = (id: string): number => {
            const element = document.getElementById(id);
            return element ? (element.getBoundingClientRect().top + window.scrollY) * 0.65 : 0;
        };
        
        setScrollOffsets({
            home: getElementOffset(SECTIONS.HOME),
            procedures: getElementOffset(SECTIONS.PROCEDURES),
            why: getElementOffset(SECTIONS.WHY),
            locale: getElementOffset(SECTIONS.LOCALE),
        });
    }, []);

    useEffect(() => {
        calculateOffsets();
        const debouncedCalculateOffsets = debounce(calculateOffsets, 200);

        window.addEventListener('scroll', debouncedCalculateOffsets);
        window.addEventListener('resize', debouncedCalculateOffsets);

        return () => {
            window.removeEventListener('scroll', debouncedCalculateOffsets);
            window.removeEventListener('resize', debouncedCalculateOffsets);
        };
    }, [calculateOffsets]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY >= scrollOffsets.locale) {
                setActive(SECTIONS.LOCALE);
            } else if (scrollY >= scrollOffsets.why) {
                setActive(SECTIONS.WHY);
            } else if (scrollY >= scrollOffsets.procedures) {
                setActive(SECTIONS.PROCEDURES);
            } else {
                setActive(SECTIONS.HOME);
            }
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollOffsets]); */

    /* const NavItem: React.FC<{ section: SECTIONS, label: string }> = ({ section, label }) => (
        <ScrollIntoView selector={`#${section}`}>
            <NavTypography>{label}</NavTypography>
            <Divider color="#20243B" hidden={active !== section} />
        </ScrollIntoView>
    ); */

    const [widthTooLow, setWidthTooLow] = useState(window.innerWidth < 380);
    const [useMobileImg, setUseMobileImg] = useState(window.innerWidth < 500)

    useEffect(() => {
        const handleResize = () => {
            setWidthTooLow(window.innerWidth < 380);
            setUseMobileImg(window.innerWidth < 500)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <FlexContainer
            sx={{
                flexDirection: 'row',
                position: 'absolute',
                height: '10%',
                backgroundColor: 'white',
                width: '100%',
                visibility: widthTooLow ? 'hidden' : 'visible',
                justifyContent: 'space-around',
                alignItems: 'center',
                zIndex: 1002,
            }}
        >
            {/* <NavItem section={SECTIONS.HOME} label="Início" />
            <NavItem section={SECTIONS.PROCEDURES} label="Procedimentos" />
            <NavItem section={SECTIONS.WHY} label="Sobre" />
            <NavItem section={SECTIONS.LOCALE} label="Localização" /> */}
            <FlexContainer width={!useMobileImg ? '400px' : '75%'}>
                <img src={Logo} width={'100%'}/>
            </FlexContainer>
        </FlexContainer>
    );
};

export default NavBar;
