import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import { FlexContainer } from "./ui/flex-container"
import Logo from "../assets/LogoBranca.png"
import { CustomTypography } from "./ui/custom-typography";

export const FakeFooter = () => {
    const title = { xs: '20px', sm: '25px', md: '25px', lg: '28px', xl: '30px' }
    const text = { xs: '17px', sm: '23px', md: '15px', lg: '21px', xl: '27px' }

    const handleLinkClick = (link: string) => {
        const url = link;
        window.open(url, '_blank');
    };

    const handleWhatsAppClick = () => {
        const url = 'https://wa.me/5521993302613';
        window.open(url, '_blank');
    };

    return (
        <FlexContainer
            sx={{
                height: 'auto',
                color: 'white',
                width: '100%',
                padding: { xs: '8%', md: '3%' },
                boxSizing: 'border-box',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(27,44,72,1) 100%)',
            }}
        >
            <FlexContainer
                sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    height: '100%',
                }}
            >
                <FlexContainer
                    sx={{
                        width: { xs: '60%', md: '30%' },
                        marginBottom: { xs: '5%', md: '0%' },
                    }}
                >
                    <img src={Logo} width={'100%'} />
                </FlexContainer>
                <FlexContainer
                    sx={{
                        width: { xs: '100%', md: '30%' },
                        flexDirection: 'column',
                        height: '70%',
                        justifyContent: 'space-evenly',
                        boxSizing: 'border-box',
                    }}
                    onClick={() => handleLinkClick('https://maps.app.goo.gl/zP15LdL1CR6RWuFp9')}
                >
                    <CustomTypography
                        sx={{
                            fontSize: title,
                            fontWeight: 500,
                            marginBottom: '3%',
                        }}
                    >
                        Endereço:
                    </CustomTypography>
                    <CustomTypography
                        sx={{
                            fontSize: text,
                            marginBottom: { xs: '5%', md: '0%' },
                        }}
                    >
                        Rua Ator Paulo Gustavo, 26, Trade Center, Sala 717
                    </CustomTypography>
                </FlexContainer>
                <FlexContainer
                    sx={{
                        width: { xs: '100%', md: '30%' },
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <CustomTypography
                        sx={{
                            fontSize: title,
                            fontWeight: 500,
                            marginBottom: '3%',
                        }}
                    >
                        Contatos:
                    </CustomTypography>
                    <FlexContainer alignItems={'center'} marginBottom={{ xs: '2%', md: '0%' }} onClick={() => handleLinkClick('https://wa.me/5521993302613')}>
                        <WhatsAppIcon sx={{ marginRight: .1, fontSize: { xs: 25, md: 30 } }} />
                        <CustomTypography
                            sx={{
                                fontSize: text,
                            }}
                        >
                            Whatsapp: (21) 99330-2613
                        </CustomTypography>
                    </FlexContainer>
                    <FlexContainer alignItems={'center'} onClick={() => handleLinkClick('https://www.instagram.com/roger.nabarro/')}>
                        <InstagramIcon sx={{ marginRight: .1, fontSize: { xs: 25, md: 30 } }} />
                        <CustomTypography
                            sx={{
                                fontSize: text,
                            }}
                        >
                            Instagram
                        </CustomTypography>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    )
}