import { CustomTypography } from "./ui/custom-typography"
import { FlexContainer } from "./ui/flex-container"

export const Footer = () => {
    const handleClick = () => {
        const url = 'https://www.linkedin.com/in/matheus-cardoso-aa63961a0/';
        window.open(url, '_blank');
    };

    return (
        <FlexContainer
            sx={{
                height: '5vh',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <CustomTypography
                sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    cursor: 'pointer'
                }}
                onClick={() => handleClick()}
            >
                Copyright © MPC. Todos direitos reservados.
            </CustomTypography>
        </FlexContainer>
    )
}