import ResponsiveGallery from 'react-responsive-gallery';
import { FlexContainer } from "./ui/flex-container"
import Clinic1 from "../assets/clinic/1.jpg"
import Clinic2 from "../assets/clinic/2.jpg"
import Clinic3 from "../assets/clinic/3.jpg"
import Clinic4 from "../assets/clinic/4.jpg"
import Grande1 from "../assets/clinic/5.jpg"
import Clinic7 from "../assets/clinic/6.jpg"
import Grande2 from "../assets/clinic/7.jpg"
import Clinic8 from "../assets/clinic/8.jpg"
import { Box, Paper } from '@mui/material';
import styled from '@emotion/styled';
import { CustomTypography } from './ui/custom-typography';
import { WhatsappButton } from './whatsapp/whatsapp-button';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    textAlign: 'center',
}));

export const ClinicSections = () => {
    const photos = [
        {
            src: Grande1,
            orderS: 1,
            orderM: 1,
            orderL: 1
        },
        {
            src: Clinic1,
            orderS: 2,
            orderM: 2,
            orderL: 2
        },
        {
            src: Clinic2,
            orderS: 3,
            orderM: 3,
            orderL: 3
        },
        {
            src: Clinic3,
            orderS: 4,
            orderM: 4,
            orderL: 4
        },
        {
            src: Clinic4,
            orderS: 5,
            orderM: 5,
            orderL: 5
        },
        {
            src: Clinic7,
            orderS: 6,
            orderM: 6,
            orderL: 6
        },
        {
            src: Clinic8,
            orderS: 7,
            orderM: 7,
            orderL: 7
        },
        {
            src: Grande2,
            orderS: 8,
            orderM: 8,
            orderL: 8
        }
    ]
    return (
        <FlexContainer
            sx={{
                flexDirection: { xs: 'column', md: 'column', lg: 'column', xl: 'row' }
            }}
        >
            <FlexContainer sx={{
                flexDirection: 'column',
                width: { xs: '90%', md: '65%', lg: '50%', xl: '25%' },
                paddingX: '5%',
                paddingTop: { xs: '5%', xl: '10%' },
                marginBottom: { xs: '5%', xl: '0%' }
            }}>
                <CustomTypography
                    sx={{
                        fontSize: { xs: '25px', md: '30px', lg: '42px' },
                        fontWeight: '600',
                        marginBottom: '2%',
                        lineHeight: '1.2',
                        textAlign: 'start'
                    }}
                >
                    Nosso Espaço
                </CustomTypography>
                <CustomTypography
                    sx={{
                        fontSize: { xs: '20px', md: '20px', lg: '22px' },
                        fontWeight: '500',
                        lineHeight: '1.2',
                        textAlign: 'start',
                    }}
                >
                    Oferecemos uma estrutura que garante conforto e um atendimento humanizado,
                    especializado e de altíssima qualidade.
                    Nosso propósito é proporcionar aos pacientes um atendimento mais rápido e confortável.
                </CustomTypography>
                <WhatsappButton sx={{
                    marginTop: '10%'
                }}
                />
            </FlexContainer>
            <Box sx={{
                boxSizing: 'border-box',
                paddingX: '3%',
                marginBottom: '3%',
                width: { xs: '100%', md: '100%', xl: '75%' }
            }}>
                <ResponsiveGallery numOfMediaPerRow={{
                    xs: 2,
                    s: 2,
                    m: 2,
                    l: 3,
                    xl: 4,
                    xxl: 4
                }}
                    media={photos}
                    useLightBox
                />
            </Box>
        </FlexContainer>

    )
}