import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'

export const MapsComponent = () => {

    return (
        <MapContainer
            style={{ width: '100%', height: '100%', borderRadius: 12 }}
            center={[-22.903348017279047, -43.11479274589272]}
            zoom={14}
            scrollWheelZoom={false}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[-22.903348017279047, -43.11479274589272]} />
        </MapContainer>
    )
}