import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { motion } from "framer-motion"

export const WhatsappIcon = () => {
    const handleClick = () => {
        const url = 'https://wa.me/5521993302613';
        window.open(url, '_blank');
    };
    return (
        <motion.div
            style={{ zIndex: 100 }}
            initial={{ opacity: .5, scale: 1, }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ ease: "linear", duration: 1, repeat: Infinity, repeatDelay: 1 }}
            onClick={() => handleClick()}
        >
            <WhatsAppIcon
                sx={{
                    visibility: { xs: 'hidden', md: 'visible' },
                    position: 'fixed',
                    right: 25,
                    bottom: 25,
                    fontSize: 65,
                    color: '#25D366',
                    ":hover": {
                        cursor: 'pointer'
                    }
                }}
            />
        </motion.div>
    )
}